import crudModule, {crudActions} from "../crud";

export default {
  ...crudModule('/property-keys'),
  actions: {
    ...crudActions('/property-keys'),
    async updateKeyboxCode({ commit }, data) {
      commit('updatekeyboxcodeReq');
      return window.apiFetch(`/property-keys`, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data)
      }).then(res => res.json()).then(json => {
        commit('updatekeyboxcodeSuc', json);
        return json;
      }).catch(err => {
        commit('updatekeyboxcodeErr');
        throw err;
      });
    },
  }
};
