import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/auth',
    component: () => import('../views/Authentication/Container.vue'),
    children: [
      {
        path: 'login',
        name: 'auth.login',
        component: () => import('../views/Authentication/Login.vue'),
      }, {
        path: 'logout',
        name: 'auth.logout',
        component: () => import('../views/Authentication/Logout.vue'),
      }, {
        path: 'forgot-password',
        name: 'auth.forgot-password',
        component: () => import('../views/Authentication/ForgotPassword.vue'),
      }, {
        path: 'reset-password/:token',
        name: 'auth.reset-password',
        component: () => import('../views/Authentication/ResetPassword.vue'),
      }
    ]
  },
  {
    path: '/',
    component: () => import('../views/Container.vue'),
    children: [
      {
        path: '',
        name: 'dashboard',
        meta: {
          title: 'Dashboard',
        },
        component: () => import('../views/Dashboard.vue'),
      },
      {
        path: 'settings/reset-password',
        name: 'settings.reset-password',
        meta: {
          title: 'Reset Password',
        },
        component: () => import('../views/SettingResetPassword.vue'),
      }, {
        path: 'absences',
        name: 'absences',
        meta: {
          title: 'Absences',
        },
        component: () => import('../views/Absences.vue'),
        children: [
          {
            path: 'request',
            name: 'absences.request',
            props: true,
            meta: {
              parentName: 'absences',
              modalId: 'request-holiday',
              title: 'Absences',
            },
            component: () => import('../views/Modals/RequestHoliday.vue'),
          }, {
            path: 'create',
            name: 'absences.create',
            props: true,
            meta: {
              parentName: 'absences',
              modalId: 'edit-absence',
              title: 'Absences',
            },
            component: () => import('../views/Modals/EditAbsence.vue'),
          }, {
            path: 'update',
            redirect: { name: 'absences' },
          }, {
            path: 'update/:absenceId',
            name: 'absences.update',
            props: true,
            meta: {
              parentName: 'absences',
              modalId: 'edit-absence',
              title: 'Absences',
            },
            component: () => import('../views/Modals/EditAbsence.vue'),
          }
        ]
      }, {
        path: 'bookings',
        name: 'bookings',
        meta: {
          title: 'Bookings',
        },
        component: () => import('../views/Bookings.vue'),
        children: [
          {
            path: 'create',
            name: 'bookings.create',
            props: true,
            meta: {
              parentName: 'bookings',
              modalId: 'edit-booking',
              title: 'Bookings',
            },
            component: () => import('../views/Modals/EditCustomer.vue'),
          }, {
            path: 'update',
            redirect: { name: 'bookings' },
          }, {
            path: 'update/:bookingId',
            name: 'bookings.update',
            props: true,
            meta: {
              parentName: 'bookings',
              modalId: 'edit-booking',
              title: 'Bookings',
            },
            component: () => import('../views/Modals/EditCustomer.vue'),
          }
        ]
      }, {
        path: 'booking-confirmations',
        name: 'booking-confirmations',
        meta: {
          title: 'Review Bookings',
        },
        component: () => import('../views/BookingConfirmations.vue'),
      }, {
        path: 'guests',
        name: 'guests',
        props: {
          type: 'guest',
        },
        meta: {
          title: 'Guests',
        },
        component: () => import('../views/Customers.vue'),
        children: [
          {
            path: 'create',
            name: 'guests.create',
            props: true,
            meta: {
              parentName: 'guests',
              modalId: 'edit-customer',
              title: 'Guests',
            },
            component: () => import('../views/Modals/EditCustomer.vue'),
          }, {
            path: 'update',
            redirect: { name: 'customers' },
          }, {
            path: 'update/:customerId',
            name: 'guests.update',
            props: true,
            meta: {
              parentName: 'guests',
              modalId: 'edit-customer',
              title: 'Guests',
            },
            component: () => import('../views/Modals/EditCustomer.vue'),
          }
        ]
      }, {
        path: 'customers',
        name: 'customers',
        props: {
          type: 'customer',
        },
        meta: {
          title: 'Customers',
        },
        component: () => import('../views/Customers.vue'),
        children: [
          {
            path: 'create',
            name: 'customers.create',
            props: true,
            meta: {
              parentName: 'customers',
              modalId: 'edit-customer',
              title: 'Customers',
            },
            component: () => import('../views/Modals/EditCustomer.vue'),
          }, {
            path: 'update',
            redirect: { name: 'customers' },
          }, {
            path: 'update/:customerId',
            name: 'customers.update',
            props: true,
            meta: {
              parentName: 'customers',
              modalId: 'edit-customer',
              title: 'Customers',
            },
            component: () => import('../views/Modals/EditCustomer.vue'),
          }
        ]
      }, {
        path: 'properties',
        name: 'properties',
        meta: {
          title: 'Properties',
        },
        component: () => import('../views/Properties.vue'),
        children: [
          {
            path: 'create',
            name: 'properties.create',
            props: true,
            meta: {
              parentName: 'properties',
              modalId: 'edit-property',
              title: 'Properties',
            },
            component: () => import('../views/Modals/EditCustomer.vue'),
          }, {
            path: 'update',
            redirect: { name: 'properties' },
          }, {
            path: 'update/:propertyId',
            name: 'properties.update',
            props: true,
            meta: {
              parentName: 'properties',
              modalId: 'edit-property',
              title: 'Properties',
            },
            component: () => import('../views/Modals/EditCustomer.vue'),
          }
        ]
      }, {
        path: 'property-keys',
        name: 'property-keys',
        meta: {
          title: 'Property Keys',
        },
        component: () => import('../views/PropertyKeys.vue')
      }, {
        path: 'no-entries',
        name: 'no-entries',
        meta: {
          title: 'No Entry Report',
        },
        component: () => import('../views/NoEntries.vue')
      }, {
        path: 'job-confirmations',
        name: 'job-confirmations',
        meta: {
          title: 'Review Jobs',
        },
        component: () => import('../views/JobConfirmations.vue'),
      }, {
        path: 'jobs-report',
        name: 'jobs-report',
        meta: {
          title: 'Jobs Report',
        },
        component: () => import('../views/JobsReport.vue'),
      }, {
        path: 'jobs',
        name: 'jobs',
        meta: {
          title: 'Jobs',
        },
        component: () => import('../views/Jobs.vue'),
        children: [
          {
            path: 'create',
            name: 'jobs.create',
            props: true,
            meta: {
              parentName: 'jobs',
              modalId: 'edit-job',
              title: 'Jobs',
            },
            component: () => import('../views/Modals/EditJob.vue'),
          }, {
            path: 'update',
            redirect: { name: 'jobs' },
          }, {
            path: 'update/:jobId',
            name: 'jobs.update',
            props: true,
            meta: {
              parentName: 'jobs',
              modalId: 'edit-job',
              title: 'Jobs',
            },
            component: () => import('../views/Modals/EditJob.vue'),
          }, {
            path: 'checklist',
            redirect: { name: 'jobs' },
          }, {
            path: 'checklist/:jobId',
            name: 'jobs.checklist',
            props: true,
            meta: {
              parentName: 'jobs',
              modalId: 'edit-job-checklist',
              title: 'Jobs',
            },
            component: () => import('../views/Modals/EditJobChecklist.vue'),
          }
        ]
      }, {
        path: 'maintenance',
        name: 'maintenance',
        meta: {
          title: 'Maintenance',
        },
        component: () => import('../views/Maintenance.vue'),
        children: [
          {
            path: 'create',
            name: 'maintenance.create',
            props: true,
            meta: {
              parentName: 'maintenance',
              modalId: 'edit-maintenance',
              title: 'Maintenance',
            },
            component: () => import('../views/Modals/EditMaintenance.vue'),
          },  {
            path: 'update',
            redirect: { name: 'maintenance' },
          }, {
            path: 'update/:maintenanceId',
            name: 'maintenance.update',
            props: true,
            meta: {
              parentName: 'maintenance',
              modalId: 'edit-maintenance',
              title: 'Maintenance',
            },
            component: () => import('../views/Modals/EditMaintenance.vue'),
          }, {
            path: 'update',
            redirect: { name: 'maintenance' },
          }, {
            path: 'update/:maintenanceId',
            name: 'maintenance.update',
            props: true,
            meta: {
              parentName: 'maintenance',
              modalId: 'edit-maintenance',
              title: 'Maintenance',
            },
            component: () => import('../views/Modals/EditMaintenance.vue'),
          }
        ]
      }, {
        path: 'maintenance/contractors',
        name: 'maintenance.contractors',
        meta: {
          title: 'Maintenance Contractors',
        },
        component: () => import('../views/MaintenanceContractors.vue'),
        children: [
          {
            path: 'create',
            name: 'maintenance.contractors.create',
            props: true,
            meta: {
              parentName: 'maintenance.contractors',
              modalId: 'edit-maintenance-contractor',
              title: 'Maintenance Contractors',
            },
            component: () => import('../views/Modals/EditMaintenanceContractor.vue'),
          }, {
            path: 'update',
            redirect: { name: 'maintenance.contractors' },
          }, {
            path: 'update/:maintenanceContractorId',
            name: 'maintenance.contractors.update',
            props: true,
            meta: {
              parentName: 'maintenance.contractors',
              modalId: 'edit-maintenance-contractor',
              title: 'Maintenance Contractors',
            },
            component: () => import('../views/Modals/EditMaintenanceContractor.vue'),
          }
        ]
      }, {
        path: 'maintenance/visits',
        name: 'maintenance.visits',
        meta: {
          title: 'Maintenance Visits',
        },
        component: () => import('../views/MaintenanceCalendar.vue'),
        children: [
        ]
      }, {
        path: 'maintenance/complete',
        name: 'maintenance.complete',
        meta: {
          title: 'Complete Maintenance',
        },
        component: () => import('../views/MaintenanceComplete.vue'),
        children: []
      }, {
        path: 'maintenance/recurring',
        name: 'maintenance.recurring',
        meta: {
          title: 'Recurring Maintenance',
        },
        component: () => import('../views/MaintenanceRecurring.vue'),
        children: [
          {
            path: 'create',
            name: 'maintenance.recurring.create',
            props: true,
            meta: {
              parentName: 'maintenance.recurring',
              modalId: 'edit-maintenance-recurring',
              title: 'Recurring Maintenance',
            },
            component: () => import('../views/Modals/EditMaintenanceContractor.vue'),
          }, {
            path: 'update',
            redirect: { name: 'maintenance.recurring' },
          }, {
            path: 'update/:recurringMaintenanceId',
            name: 'maintenance.recurring.update',
            props: true,
            meta: {
              parentName: 'maintenance.recurring',
              modalId: 'edit-maintenance-recurring',
              title: 'Recurring Maintenance',
            },
            component: () => import('../views/Modals/EditMaintenanceContractor.vue'),
          }
        ]
      }, {
        path: 'maintenance/details/:maintenanceId',
        name: 'maintenance.details',
        props: true,
        meta: {
          title: 'Maintenance Details',
        },
        component: () => import('../views/MaintenanceDetails.vue'),
      }, {
        path: 'jobs/details',
        redirect: { name: 'jobs' },
      }, {
        path: 'jobs/details/:jobId',
        name: 'jobs.details',
        props: true,
        meta: {
          title: 'Job Details',
        },
        component: () => import('../views/JobDetails.vue'),
      }, {
        path: 'checklist-templates',
        name: 'checklist-templates',
        meta: {
          title: 'Checklist Templates',
        },
        component: () => import('../views/ChecklistTemplates.vue'),
        children: [
          {
            path: 'create',
            name: 'checklist-templates.create',
            props: true,
            meta: {
              parentName: 'checklist-templates',
              modalId: 'edit-checklist-template',
              title: 'Checklist Templates',
            },
            component: () => import('../views/Modals/EditChecklistTemplate.vue'),
          }, {
            path: 'update',
            redirect: { name: 'checklist-templates' },
          }, {
            path: 'update/:templateId',
            name: 'checklist-templates.update',
            props: true,
            meta: {
              parentName: 'checklist-templates',
              modalId: 'edit-checklist-template',
              title: 'Checklist Templates',
            },
            component: () => import('../views/Modals/EditChecklistTemplate.vue'),
          }
        ]
      }, {
        path: 'invoices',
        name: 'invoices',
        meta: {
          title: 'Invoices',
        },
        component: () => import('../views/Invoices.vue'),
        children: [
          {
            path: 'create',
            name: 'invoices.create',
            props: true,
            meta: {
              parentName: 'invoices',
              modalId: 'edit-invoice',
              title: 'Invoices',
            },
            component: () => import('../views/Modals/EditPropertyCost.vue'),
          }, {
            path: 'update',
            redirect: { name: 'invoices' },
          }, {
            path: 'update/:costId',
            name: 'invoices.update',
            props: true,
            meta: {
              parentName: 'invoices',
              modalId: 'edit-invoice',
              title: 'Invoices',
            },
            component: () => import('../views/Modals/EditPropertyCost.vue'),
          }
        ]
      }, {
        path: 'statements',
        name: 'statements',
        meta: {
          title: 'Owner Statements',
        },
        component: () => import('../views/Statements.vue'),
        children: [
          {
            path: 'update',
            redirect: { name: 'statements' },
          }, {
            path: 'update/:statementId',
            name: 'statements.update',
            props: true,
            meta: {
              parentName: 'statements',
              modalId: 'edit-statement',
              title: 'Statements',
            },
          }
        ]
      }, {
        path: 'monthly-accounts',
        name: 'monthly-accounts',
        meta: {
          title: 'Monthly Accounts',
        },
        component: () => import('../views/MonthlyAccounts.vue')
      }, {
        path: 'users',
        name: 'users',
        meta: {
          title: 'Users',
        },
        component: () => import('../views/Users.vue'),
        children: [
          {
            path: 'create',
            name: 'users.create',
            props: true,
            meta: {
              parentName: 'users',
              modalId: 'edit-user',
              title: 'Users',
            },
            component: () => import('../views/Modals/EditCustomer.vue'),
          }, {
            path: 'update',
            redirect: { name: 'users' },
          }, {
            path: 'update/:userId',
            name: 'users.update',
            props: true,
            meta: {
              parentName: 'users',
              modalId: 'edit-user',
              title: 'Users',
            },
            component: () => import('../views/Modals/EditCustomer.vue'),
          }
        ]
      }, {
        path: 'rotas',
        name: 'rotas',
        meta: {
          title: 'Rota',
        },
        component: () => import('../views/Rota.vue'),
        children: [
          {
            path: 'assign',
            name: 'rotas.assign',
            props: true,
            meta: {
              parentName: 'rotas',
              modalId: 'assign-job',
              title: 'Rotas',
            },
            component: () => import('../views/Modals/EditCustomer.vue'),
          }, {
            path: 'update',
            redirect: { name: 'rotas' },
          }, {
            path: 'update/:rotaId',
            name: 'rotas.update',
            props: true,
            meta: {
              parentName: 'rotas',
              modalId: 'assign-job',
              title: 'Rota',
            },
            component: () => import('../views/Modals/EditCustomer.vue'),
          }
        ],
      }, {
        path: 'stock',
        name: 'stock',
        meta: {
          title: 'Stock',
        },
        component: () => import('../views/Stock.vue'),
        children: [
          {
            path: 'create',
            name: 'stock.create',
            props: true,
            meta: {
              parentName: 'stock',
              modalId: 'edit-stock',
              title: 'Stock',
            },
            component: () => import('../views/Modals/EditCustomer.vue'),
          }, {
            path: 'update',
            redirect: { name: 'stock' },
          }, {
            path: 'update/:stockId',
            name: 'stock.update',
            props: true,
            meta: {
              parentName: 'stock',
              modalId: 'edit-stock',
              title: 'Stock',
            },
            component: () => import('../views/Modals/EditCustomer.vue'),
          }, {
            path: 'book-in-out',
            redirect: { name: 'stock' },
          }, {
            path: 'book-in-out/:stockId',
            name: 'stock.book-in-out',
            props: true,
            meta: {
              parentName: 'stock',
              modalId: 'book-in-out-stock',
              title: 'Stock',
            },
            component: () => import('../views/Modals/EditCustomer.vue'),
          }
        ]
      }, {
        path: 'teams',
        name: 'teams',
        meta: {
          title: 'Teams',
        },
        component: () => import('../views/Teams.vue'),
        children: [
          {
            path: 'create',
            name: 'teams.create',
            props: true,
            meta: {
              parentName: 'teams',
              modalId: 'edit-team',
              title: 'Teams',
            },
            component: () => import('../views/Modals/EditCustomer.vue'),
          }, {
            path: 'update',
            redirect: { name: 'teams' },
          }, {
            path: 'update/:teamId',
            name: 'teams.update',
            props: true,
            meta: {
              parentName: 'teams',
              modalId: 'edit-team',
              title: 'Teams',
            },
            component: () => import('../views/Modals/EditCustomer.vue'),
          }
        ]
      }, {
        path: 'timesheets',
        name: 'timesheets',
        meta: {
          title: 'Timesheets',
        },
        component: () => import('../views/Timesheets.vue'),
      }, {
        path: 'reports',
        component: () => import('../views/Reporting.vue'),
        children: [
          {
            path: '',
            name: 'reports',
            meta: {
              title: 'Reporting',
            },
            component: () => import('../views/Reports/Index.vue'),
          }, {
            path: 'guest',
            name: 'reports.guest',
            meta: {
              title: 'Guest Report',
            },
            component: () => import('../views/Reports/Guest.vue'),
          }, {
            path: 'inoutflow',
            name: 'reports.inoutflow',
            meta: {
              title: 'Inflow & Outflow Report',
            },
            component: () => import('../views/Reports/InOutflow.vue'),
          }, {
            path: 'owner-account',
            name: 'reports.owner-account',
            meta: {
              title: 'Owner Account Report',
            },
            component: () => import('../views/Reports/OwnerAccount.vue'),
          }, {
            path: 'jobs',
            name: 'reports.jobs',
            meta: {
              title: 'Job Report',
            },
            component: () => import('../views/Reports/Jobs.vue'),
          }, {
            path: 'occupancy',
            name: 'reports.occupancy',
            meta: {
              title: 'Occupancy Report',
            },
            component: () => import('../views/Reports/Occupancy.vue'),
          }, {
            path: 'properties',
            name: 'reports.properties',
            meta: {
              title: 'Property Report',
            },
            component: () => import('../views/Reports/Property.vue'),
          }
        ]
      }, {
        path: 'attendances',
        name: 'attendances',
        meta: {
          title: 'Clock Ins/Outs',
        },
        component: () => import('../views/Attendances.vue'),
        children: [
          {
            path: 'create',
            name: 'attendances.create',
            props: true,
            meta: {
              parentName: 'attendances',
              modalId: 'edit-attendance',
              title: 'Attendance',
            },
            component: () => import('../views/Modals/EditAttendance.vue'),
          }, {
            path: 'update/:attendanceId',
            name: 'attendances.update',
            props: true,
            meta: {
              parentName: 'attendances',
              modalId: 'edit-attendance',
              title: 'Attendance',
            },
            component: () => import('../views/Modals/EditAttendance.vue'),
          }
        ]
      }, {
        path: 'system',
        name: 'system',
        meta: {
          title: 'System',
        },
        component: () => import('../views/System.vue'),
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router


