<template>
  <div class="notification-container">
    <slot></slot>
    <div class="notification__gradient" v-if="notifications.length"></div>
    <b-card class="notification" :key="i" v-for="(notification, i) in notifications">
      <div :class="`notification__indicator notification__indicator--${notification.variant}`"></div>
      <div>
        <p>{{ notification.title }}</p>
        <p>{{ notification.body }}</p>
      </div>
    </b-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      notifications: []
    }
  },
  mounted() {
    this.$root.$on('notif.http-error', (notification) => {
      this.notifications.push({ ...notification, variant: 'error' });
      setTimeout(() => this.notifications.shift(), 5000);
    });
  },
}
</script>

<style lang="scss" scoped>
div.notification-container {
  position: relative;
  height: 100vh;
  width: 100vw;
}

div.notification__gradient {
  height: 10rem;
  position: absolute;
  bottom: 0; left: 0;
  width: 100vw;
  pointer-events: none;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(128, 128, 128, 0.15) 100%);
}

div.notification {
  border-radius: 4pt;
  position: absolute;
  bottom: 16pt; left: 50%;
  padding: 0;
  width: 36rem;
  transform: translate(-50%, 0);
  z-index: 9999!important;

  div.card-body {
    display: flex;
    flex-direction: row;
    padding: 8pt;

    div:not(.notification__indicator) {
      align-self: center;
      padding: 0 12pt;
      flex: 1;
    }

    p {
      margin: 0;

      &:first-child {
        font-weight: bold;
        font-size: 1.2rem;
      }
    }
  }
}

div.notification__indicator {
  background: #404345;
  border-radius: 2pt;
  max-width: 4pt;
  flex: 1 4pt;

  &:before {
    content: '';
  }

  &.notification__indicator--error {
    background: #dc3545;
  }
}
</style>
