export const crudActions = (path) => ({
  fetch({ commit }, data = 1) {
    let url = `${path}`;
    if (typeof data === 'number' && data > 1) {
      url = `${path}?page=${data}`;
    } else if (typeof data === 'object') {
      const params = Object.keys(data).map(key => `${key}=${encodeURIComponent(data[key])}`).join('&')
      url = `${path}?${params}`
    }
    commit('fetchReq');
    return window.apiFetch(url).then(res => res.json()).then(json => {
      commit('fetchSuc', json);
      return json;
    }).catch(err => {
      commit('fetchErr');
      throw err;
    });
  },
  fetchSingle({ commit }, id) {
    return window.apiFetch(`${path}/${id}`).then(res => res.json()).then(json => {
      return json.data ? json.data : json;
    }).catch(err => {
      commit('fetchErr');
      throw err;
    });
  },
  updateOrCreate({ commit }, data) {
    commit('updateOrCreateReq');
    return window.apiFetch(data.id ? `${path}/${data.id}` : path, {
      method: data.id ? 'PUT' : 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data)
    }).then(res => res.json()).then(json => {
      commit('updateOrCreateSuc', json);
      return json;
    }).catch(err => {
      commit('updateOrCreateErr');
      throw err;
    });
  },
  delete({ commit }, data) {
    commit('deleteReq');
    let urlend = data
    if (data instanceof Object && data.id) {
        urlend = `${data.id}?${Object.keys(data).filter(k => k !== 'id').map(k => `${k}=${data[k]}`).join('&')}`
    }
    return window.apiFetch(`${path}/${urlend}`, {
      method: 'DELETE',
    }).then(() => {
      commit('deleteSuc');
    }).catch(err => {
      commit('deleteErr');
      throw err;
    })
  }
});

export const crudMutations = () => ({
  fetchReq(state) {
    state.loading = true;
  },
  fetchSuc(state, json) {
    state.loading = false;
    state.data = json.data ?? json;
    state.lastPage = json.last_page;
  },
  fetchErr(state) {
    state.loading = false;
    state.lastPage = 1;
    state.data = [];
  },
  updateOrCreateReq(state) {
    state.saving = true;
  },
  updateOrCreateSuc(state) {
    state.saving = false;
  },
  updateOrCreateErr(state) {
    state.saving = false;
  },
  deleteReq(state) {
    state.deleting = true;
  },
  deleteSuc(state) {
    state.deleting = false;
  },
  deleteErr(state) {
    state.deleting = false;
  }
});

export const crudState = () => ({
  deleting: false,
  loading: false,
  saving: false,
  lastPage: 1,
  data: [],
});

export default (path, namespaced = true) => ({
  namespaced,
  actions: crudActions(path),
  mutations: crudMutations(),
  state: crudState(),
});
