import crudModule, { crudActions } from "../crud";

export default {
  ...crudModule('/rotas'),
  actions: {
    ...crudActions('/rotas'),
    updateOrCreate({ commit }, data) {
      const uri = `/rotas`;
      const reqOpts = {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      };

      commit('updateOrCreateReq');
      return window.apiFetch(uri, reqOpts).then(res => res.json()).then(json => {
        commit('updateOrCreateSuc', json);
        return json;
      }).catch(err => {
        commit('updateOrCreateErr', err);
        throw err;
      });
    },
  }
};
