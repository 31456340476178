import crudModule, { crudActions } from "../crud";

export default {
  ...crudModule('/bookings'),
  actions: {
    ...crudActions('/bookings'),
    async approveBooking({ commit }, id) {
      commit('approvebookingsReq');
      return window.apiFetch(`/bookings/${id}/approve`, {
        method: 'POST',
      }).then(res => res.json()).then(json => {
        commit('approvebookingsSuc', json);
        return json;
      }).catch(err => {
        commit('approvebookingsErr');
        throw err;
      });
    },
  }
};
