import { crudActions, crudMutations, crudState } from "../crud";

export default {
  namespaced: true,
  actions: {
    ...crudActions('/users'),
    async forceUnauth(_, data) {
      const reqOpts = {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      };

      return window.apiFetch(`/users/${data.id}/unauth`, reqOpts);
    },
  },
  mutations: {
    ...crudMutations(),
  },
  state: {
    ...crudState(),
  }
}
