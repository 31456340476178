<template>
  <div class="bg-dark vh-100">
    <notification>
      <transition name="fade" mode="out-in">
        <router-view v-if="initialised" />
        <div class="d-flex flex-column justify-content-center vh-100" v-if="!initialised">
          <img class="mb-4 mx-auto" src="./assets/logo.svg" alt="Toast Lettings" width="150">
          <b-spinner class="align-self-center" variant="light"></b-spinner>
        </div>
      </transition>
    </notification>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import Notification from './components/Notification.vue';
export default {
  components: { Notification },
  async mounted() {
    const csrfReq = window.apiFetch('/csrf-cookie').then(res => {
      if (res.status !== 204) {
        throw new Error('CSRF Token load failure');
      }
    }).catch(() => {
      // window.location.reload();
    });

    const checkReq = this.check().catch(err => {
      if (err.response && err.response.status === 401) {
        if (!this.$route.name.startsWith('auth.')) {
          this.$router.push({ name: 'auth.login' });
        }
      }
    });

    if (this.$route.meta.title) {
      document.title = `${this.$route.meta.title} - Toast Lettings CRM`;
    } else {
      document.title = 'Toast Lettings CRM';
    }

    try {
      const [csrfRes, checkRes] = await Promise.all([csrfReq, checkReq]);
      csrfRes; // ESLint complains without this line, that we're not using 'csrfRes'

      if (checkRes.is_clocked_in) {
        this.setClockedIn(true);
      }

      if (!checkRes.has_set_password) {
        this.$router.push({ name: 'settings.reset-password' });
      }

      this.initialised = true;
    } catch (err) {
      this.initialised = true;
    }
  },
  data() {
    return {
      initialised: false
    };
  },
  methods: {
    ...mapActions('timesheets', ['setClockedIn']),
    ...mapActions('auth', ['check']),
    ...mapState('auth', ['user']),
  },
  watch: {
    '$route'(value) {
      if (value.meta.title) {
        document.title = `${value.meta.title} - Toast Lettings CRM`;
      } else {
        document.title = 'Toast Lettings CRM';
      }
    }
  }
}
</script>

<style lang="scss">
$border-radius: 0;
$theme-colors: (
  "primary": #1A1A1A,
  "dark": #1A1A1A,
);

@import "bootstrap";
@import "bootstrap-vue";
@import '@fortawesome/fontawesome-free';

.fade-enter-active, .fade-leave-active {
  transition: opacity 250ms;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}

.modal-content, .popover {
  border-radius: 0;
}
</style>
