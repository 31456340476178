import { crudActions, crudMutations, crudState } from "../crud";

export default {
  namespaced: true,
  actions: {
    ...crudActions('/jobs'),
    async clockIn({ commit }, id) {
      commit('clockInOutReq');
      return window.apiFetch(`/jobs/${id}/clock-in`, {
        method: 'POST',
      }).then(res => res.json()).then(json => {
        commit('clockInOutSuc', json);
        return json;
      }).catch(err => {
        commit('clockInOutErr');
        throw err;
      });
    },
    async clockOut({ commit }, id) {
      commit('clockInOutReq');
      return window.apiFetch(`/jobs/${id}/clock-out`, {
        method: 'POST',
      }).then(res => res.json()).then(json => {
        commit('clockInOutSuc', json);
        return json;
      }).catch(err => {
        commit('clockInOutErr');
        throw err;
      });
    },
    setClockedIn({ commit }, yesNo) {
      commit('setClockedIn', yesNo);
    },
    async approveAttendance({ commit }, id) {
      commit('approveAttendanceReq');
      return window.apiFetch(`/attendance/${id}/approve`, {
        method: 'POST',
      }).then(res => res.json()).then(json => {
        commit('approveAttendanceSuc', json);
        return json;
      }).catch(err => {
        commit('approveAttendanceErr');
        throw err;
      });
    },
    async rejectAttendance({ commit }, id) {
      commit('rejectAttendanceReq');
      return window.apiFetch(`/attendance/${id}/reject`, {
        method: 'POST',
      }).then(res => res.json()).then(json => {
        commit('rejectAttendanceSuc', json);
        return json;
      }).catch(err => {
        commit('rejectAttendanceErr');
        throw err;
      });
    },
    async updateAttendanceStartEnd({ commit }, data) {
      commit('updateAttendanceStartEndReq');
      return window.apiFetch(`/attendance/${data.id}`, {
        method: 'PUT',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({start: data.start, end: data.end})
      }).then(res => res.json()).then(json => {
        commit('updateAttendanceStartEndSuc', json);
        return json;
      }).catch(err => {
        commit('updateAttendanceStartEndErr');
        throw err;
      });
    },
    async fetchReport({ commit }, data) {
      commit('fetchReportReq');
      return window.apiFetch(
        '/jobs-report?' + Object.keys(data).map(key => `${key}=${encodeURIComponent(data[key])}`).join('&')
      )
        .then(res => res.json()).then(json => {
        commit('fetchReportSuc', json);
        return json;
      }).catch(err => {
        commit('fetchReportErr');
        throw err;
      });
    }
  },
  mutations: {
    ...crudMutations(),
    clockInOutReq(state) {
      state.loading = true;
    },
    clockInOutSuc(state, json) {
      state.loading = false;
      state.clockedIn = json.clockedIn;
    },
    clockInOutErr(state) {
      state.loading = false;
    },
    setClockedIn(state, yesNo) {
      state.clockedIn = yesNo;
    },
    fetchReportReq(state) {
      state.loading = true;
    },
    fetchReportSuc(state, json) {
      state.loading = false;
      state.clockedIn = json.clockedIn;
    },
    fetchReportErr(state) {
      state.loading = false;
    },
  },
  state: {
    ...crudState(),
    clockedIn: false,
  }
};
