const actions = {
  async check({ commit }) {
    commit('checkReq');
    return await window.apiFetch('/me', { redirect: 'manual' }).then(res => res.json()).then(json => {
      commit('checkSuccess', json);
      return json;
    }).catch(err => {
      commit('checkError', err);
      throw err;
    });
  },
  logout({ commit }) {
    const reqOpts = {
      method: 'POST',
      redirect: 'manual',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
    };

    commit('logoutReq');
    return window.apiFetch('/logout', reqOpts).then(res => {
      commit('logoutSuccess');
      return res;
    }).catch(err => {
      commit('logoutError', err);
      throw err;
    });
  },
  login({ commit }, { email, password }) {
    const reqOpts = {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email, password })
    };

    commit('loginReq');
    return window.apiFetch('/login', reqOpts).then(res => {
      commit('loginSuccess');
      return res;
    }).catch(err => {
      commit('loginError', err);
      throw err;
    });
  },
  changePassword({ commit }, data) {
    const reqOpts = {
      method: 'PUT',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data)
    };

    commit('changePasswordReq');
    return window.apiFetch('/user/password', reqOpts).then(res => {
      commit('changePasswordSuccess');
      return res;
    }).catch(err => {
      commit('changePasswordError', err);
      throw err;
    });
  },
  forgot({ commit }, data) {
    const reqOpts = {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data)
    };

    commit('forgotPasswordReq');
    return window.apiFetch('/forgot-password', reqOpts).then(res => {
      commit('forgotPasswordSuccess');
      return res;
    }).catch(err => {
      commit('forgotPasswordError', err);
      throw err;
    });
  },
  reset({ commit }, data) {
    const reqOpts = {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data)
    };

    commit('resetPasswordReq');
    return window.apiFetch('/reset-password', reqOpts).then(res => {
      commit('resetPasswordSuccess');
      return res;
    }).catch(err => {
      commit('resetPasswordError', err);
      throw err;
    });
  },
};

const mutations = {
  checkReq(state) {
    state.loading = true;
    state.user = {};
  },
  checkSuccess(state, data) {
    state.loading = false;
    state.user = data;
  },
  checkError(state) {
    state.loading = false;
    state.user = {};
  },
  loginReq(state) {
    state.loading = true;
    state.user = {};
  },
  loginSuccess(state, data) {
    state.loading = false;
    state.user = data;
  },
  loginError(state) {
    state.loading = false;
    state.user = {};
  },
  logoutReq(state) {
    state.loading = true;
  },
  logoutSuccess(state) {
    state.loading = false;
    state.user = {};
  },
  logoutError(state) {
    state.loading = false;
  },
  changePasswordReq(state) {
    state.loading = true;
  },
  changePasswordSuccess(state) {
    state.loading = false;
    state.user = {};
  },
  changePasswordError(state) {
    state.loading = false;
  },
  forgotPasswordReq(state) {
    state.loading = true;
  },
  forgotPasswordSuccess(state) {
    state.loading = false;
    state.user = {};
  },
  forgotPasswordError(state) {
    state.loading = false;
  }
}

export default {
    namespaced: true,
    actions,
    mutations,
    state: {
      loading: false,
      user: {},
    },
};
