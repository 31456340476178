import BootstrapVue, { BootstrapVueIcons } from 'bootstrap-vue'
import cookie from 'cookie'
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import { library } from '@fortawesome/fontawesome-svg-core'
import { faAddressCard } from '@fortawesome/free-solid-svg-icons/faAddressCard'
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons/faAngleLeft'
import { faAngleRight } from '@fortawesome/free-solid-svg-icons/faAngleRight'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons/faArrowLeft'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons/faArrowRight'
import { faBars } from '@fortawesome/free-solid-svg-icons/faBars'
import { faChartLine } from '@fortawesome/free-solid-svg-icons/faChartLine'
import { faCalendar } from '@fortawesome/free-solid-svg-icons/faCalendar'
import { faCheck } from '@fortawesome/free-solid-svg-icons/faCheck'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons/faChevronDown'
import { faChevronUp } from '@fortawesome/free-solid-svg-icons/faChevronUp'
import { faColumns } from '@fortawesome/free-solid-svg-icons/faColumns'
import { faCog } from '@fortawesome/free-solid-svg-icons/faCog'
import { faFileContract } from '@fortawesome/free-solid-svg-icons/faFileContract'
import { faDownload } from '@fortawesome/free-solid-svg-icons/faDownload'
import { faHandSparkles } from '@fortawesome/free-solid-svg-icons/faHandSparkles'
import { faHotel } from '@fortawesome/free-solid-svg-icons/faHotel'
import { faClipboard } from '@fortawesome/free-solid-svg-icons/faClipboard'
import { faFileInvoice } from '@fortawesome/free-solid-svg-icons/faFileInvoice'
import { faFileInvoiceDollar } from '@fortawesome/free-solid-svg-icons/faFileInvoiceDollar'
import { faKey } from '@fortawesome/free-solid-svg-icons/faKey'
import { faRedo } from '@fortawesome/free-solid-svg-icons/faRedo'
import { faPen } from '@fortawesome/free-solid-svg-icons/faPen'
import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus'
import { faPumpSoap } from '@fortawesome/free-solid-svg-icons/faPumpSoap'
import { faShippingFast } from '@fortawesome/free-solid-svg-icons/faShippingFast'
import { faStopwatch } from '@fortawesome/free-solid-svg-icons/faStopwatch'
import { faTasks } from '@fortawesome/free-solid-svg-icons/faTasks'
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes'
import { faTrash } from '@fortawesome/free-solid-svg-icons/faTrash'
import { faTrashRestore } from '@fortawesome/free-solid-svg-icons/faTrashRestore'
import { faUmbrellaBeach } from '@fortawesome/free-solid-svg-icons/faUmbrellaBeach'
import { faUpload } from '@fortawesome/free-solid-svg-icons/faUpload'
import { faUsers } from '@fortawesome/free-solid-svg-icons/faUsers'
import { faUserPlus } from '@fortawesome/free-solid-svg-icons/faUserPlus'
import { faList } from '@fortawesome/free-solid-svg-icons/faList'
import { faClock } from '@fortawesome/free-solid-svg-icons/faClock'
import { faEye } from '@fortawesome/free-solid-svg-icons/faEye'
import { faHouseUser } from '@fortawesome/free-solid-svg-icons/faHouseUser'
import { faUser } from '@fortawesome/free-solid-svg-icons/faUser'
import { faBan } from '@fortawesome/free-solid-svg-icons/faBan'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons/faEnvelope'
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons/faSignOutAlt'

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { flare } from "@flareapp/flare-client";
import { flareVue } from "@flareapp/flare-vue";

library.add(faAddressCard);
library.add(faAngleLeft);
library.add(faAngleRight);
library.add(faArrowLeft);
library.add(faArrowRight);
library.add(faBars);
library.add(faCalendar);
library.add(faChartLine);
library.add(faCheck);
library.add(faChevronDown);
library.add(faChevronUp);
library.add(faColumns);
library.add(faCog);
library.add(faDownload);
library.add(faHandSparkles);
library.add(faHotel);
library.add(faClipboard);
library.add(faFileInvoice);
library.add(faFileInvoiceDollar);
library.add(faFileContract);
library.add(faKey);
library.add(faRedo);
library.add(faPen);
library.add(faPlus);
library.add(faPumpSoap);
library.add(faShippingFast);
library.add(faStopwatch);
library.add(faTasks);
library.add(faTimes);
library.add(faTrash);
library.add(faTrashRestore);
library.add(faUmbrellaBeach);
library.add(faUpload);
library.add(faUsers);
library.add(faUserPlus);
library.add(faList);
library.add(faClock);
library.add(faEye);
library.add(faHouseUser);
library.add(faUser);
library.add(faBan);
library.add(faEnvelope);
library.add(faSignOutAlt);

Vue.component('fa-icon', FontAwesomeIcon)

Vue.config.productionTip = false

Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);

flare.light('1KCGPV0Cf0Q7lScVx5CQzeCT9uBbeJFp');
Vue.use(flareVue);

window.apiFetch = async (url, opts) => {
  const headers = {
    'Accept': 'text/json',
    'X-XSRF-TOKEN': cookie.parse(document.cookie)['XSRF-TOKEN'],
    'X-Requested-By': 'XMLHttpRequest',
    ...(opts && opts.headers)
  };
  const endpoint = process.env.VUE_APP_ENDPOINT + url;
  const response = await fetch(endpoint, { credentials: 'include', ...opts, headers }).catch(() => {
    app.$root.$bvModal.msgBoxOk('This may be because there was not a successful internet connection. Any attempted actions were unsuccessful, please find an internet connection and try again.' , {
      title: 'Failed',
      okVariant: 'primary',
      centered: true,
      okTitle: 'Ok'
    })
  })

  if (!response.ok) {
    let data;
    try {
      data = await response.json()
    } catch {
      data = {}
    }
    switch (response.status) {
      case 401:
        // Don't prompt login on the request to check if they're logged in.
        if (url == '/me') break;

        app.$root.$bvModal.msgBoxOk('It seems you are not currently logged in, this may be because your session timed out.', {
          title: 'Unauthenticated',
          okVariant: 'primary',
          centered: true,
          okTitle: 'Login'
        }).then(() => {
          app.$router.push({name: 'auth.login'});
        })

        break;
      case 403:
        app.$root.$emit('notif.http-error', {
          status: response.status,
          title: 'Permission Denied',
          body: data.message ? data.message : 'You need permissions to perform this action. Please refresh the page and try again, if you\'ve recently been given new permissions.',
        })
        break;
      case 422:
        app.$root.$emit('notif.http-error', {
          status: response.status,
          title: 'Validation Error',
          body: data.message ? data.message : 'Something does not look right with the values provided to this form. Please check the form and try submitting again.',
        })
        break;
      case 500:
        app.$root.$emit('notif.http-error', {
          status: response.status,
          title: 'Server Error',
          body: response.data.message ? response.data.message : 'An unexpected error occurred. Please refresh the page and try again later.',
        })
        break;
      default:
        app.$root.$emit('notif.http-error', {
          status: 0,
          title: 'Unknown Error',
          body: 'An unknown error has happened. This may be because there was not a successful internet connection. Please try refreshing the page.',
        })
    }

    throw { ...new Error(response.statusText), response };
  }
  return response;
}

const app = new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
