import { crudActions, crudMutations, crudState } from "../crud";

export default {
  namespaced: true,
  actions: {
    ...crudActions('/maintenance'),
    async addComment({ commit }, { maintenanceId, message, column }) {
      return window.apiFetch(`/maintenance/${maintenanceId}/comment`, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ message, column })
      }).then(res => res.json()).then(json => {
        commit('addCommentSuc', json);
        return json;
      }).catch(err => {
        commit('addCommentErr');
        throw err;
      });
    },
    async fetchTable({ commit }) {
      commit('fetchTableReq');
      const self = this;
      return window.apiFetch(`/maintenance/table`, {
        method: 'GET',
      }).then(res => res.json()).then(json => {
        commit('fetchTableSuc', json);
        self.tableData = json.data;

        return json.data;
      }).catch(err => {
        commit('fetchTableErr');
        throw err;
      });
    },
    async dashboard({ commit }) {
      commit('dashboardReq');
      return window.apiFetch(`/maintenance/dashboard`, {
        method: 'GET',
      }).then(res => res.json()).then(json => {
        commit('dashboardSuc', json);
        return json;
      }).catch(err => {
        commit('dashboardErr');
        throw err;
      });
    },
    async clockIn({ commit }, id) {
      commit('clockInOutReq');
      return window.apiFetch(`/maintenance/${id}/clock-in`, {
        method: 'POST',
      }).then(res => res.json()).then(json => {
        commit('clockInOutSuc', json);
        return json;
      }).catch(err => {
        commit('clockInOutErr');
        throw err;
      });
    },
    async clockOut({ commit }, id) {
      commit('clockInOutReq');
      return window.apiFetch(`/maintenance/${id}/clock-out`, {
        method: 'POST',
      }).then(res => res.json()).then(json => {
        commit('clockInOutSuc', json);
        return json;
      }).catch(err => {
        commit('clockInOutErr');
        throw err;
      });
    },
    setClockedIn({ commit }, yesNo) {
      commit('setClockedIn', yesNo);
    }
  },
  mutations: {
    ...crudMutations(),
    clockInOutReq(state) {
      state.loading = true;
    },
    clockInOutSuc(state, json) {
      state.loading = false;
      state.clockedIn = json.clockedIn;
    },
    clockInOutErr(state) {
      state.loading = false;
    },
    setClockedIn(state, yesNo) {
      state.clockedIn = yesNo;
    }
  },
  state: {
    ...crudState(),
    clockedIn: false,
    tableData: [],
  }
};
