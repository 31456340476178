import { crudActions, crudMutations, crudState } from "../crud";

export default {
  namespaced: true,
  actions: {
    ...crudActions('/timesheets'),
    async clockIn({ commit }) {
      commit('clockInOutReq');
      return window.apiFetch('/clock-in', {
        method: 'POST',
      }).then(res => res.json()).then(json => {
        commit('clockInOutSuc', json);
        return json;
      }).catch(err => {
        commit('clockInOutErr');
        throw err;
      });
    },
    async clockOut({ commit }) {
      commit('clockInOutReq');
      return window.apiFetch('/clock-out', {
        method: 'POST',
      }).then(res => res.json()).then(json => {
        commit('clockInOutSuc', json);
        return json;
      }).catch(err => {
        commit('clockInOutErr');
        throw err;
      });
    },
    setClockedIn({ commit }, yesNo) {
      commit('setClockedIn', yesNo);
    }
  },
  mutations: {
    ...crudMutations(),
    clockInOutReq(state) {
      state.loading = true;
    },
    clockInOutSuc(state, json) {
      state.loading = false;
      state.clockedIn = json.clockedIn;
    },
    clockInOutErr(state) {
      state.loading = false;
    },
    setClockedIn(state, yesNo) {
      state.clockedIn = yesNo;
    }
  },
  state: {
    ...crudState(),
    clockedIn: false,
  }
}
