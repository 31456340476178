export default {
  actions: {
    async upload(_, file, uploadableId, uploadableType) {
      const formData = new FormData();
      formData.append('file', file);
      formData.append('uploadable_id', uploadableId);
      formData.append('uploadable_type', uploadableType);
      
      // commit('uploadReq');
      return window.apiFetch('/upload', {
        method: 'POST',
        body: formData,
      }).then(res => res.json()).then(json => {
        // commit('uploadSuc', json);
        return json;
      }).catch(err => {
        // commit('uploadErr');
        throw err;
      });
    },
  }
}