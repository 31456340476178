import moment from 'moment'

const actions = {
  fetch({ commit }, { after, before, userId }) {
    const uri = userId
      ? `/users/${userId}/absences`
      : `/absences?before=${moment(before).format('YYYY-MM-DD')}&after=${moment(after).format('YYYY-MM-DD')}`;

    commit('fetchReq');
    return window.apiFetch(uri).then(res => res.json()).then(json => {
      commit('fetchSuc', json);
      return json;
    }).catch(err => {
      commit('fetchErr', err);
      throw err;
    });
  },
  updateOrCreate({ commit }, data) {
    const uri = `/users/${data.absentable_id}/absences${data.id ? `/${data.id}` : ''}`;
    const reqOpts = {
      method: data.id ? 'PUT' : 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    };

    commit('updateOrCreateReq');
    return window.apiFetch(uri, reqOpts).then(res => res.json()).then(json => {
      commit('updateOrCreateSuc', json);
      return json;
    }).catch(err => {
      commit('updateOrCreateErr', err);
      throw err;
    });
  },
  delete({ commit }, data) {
    const uri = `/users/${data.absentable_id}/absences/${data.id}`;
    const reqOpts = {
      method: 'DELETE',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      }
    };

    commit('deleteReq');
    return window.apiFetch(uri, reqOpts).then(() => {
      commit('deleteSuc');
    }).catch(err => {
      commit('deleteErr');
      throw err;
    });
  },
};

const mutations = {
  fetchReq(state) {
    state.loading = true;
    state.absences = [];
  },
  fetchSuc(state, json) {
    state.loading = false;
    state.absences = json;
  },
  fetchErr(state) {
    state.loading = false;
  }
};

export default {
    namespaced: true,
    actions,
    mutations,
    state: {
      loading: false,
      absences: [],
    }
};
