import Vue from 'vue'
import Vuex from 'vuex'

import maintenanceContractorTrades from './modules/maintenance-contractor-trades'
import maintenanceContractors from './modules/maintenance-contractors'
import maintenanceRecurrings from './modules/maintenance-recurrings'
import maintenanceVisits from './modules/maintenance-visits'
import checklistTemplates from './modules/checklist-templates'
import bookingPayments from './modules/booking-payments'
import propertyKeys from './modules/property-keys'
import bankAccounts from './modules/bank-accounts'
import maintenance from './modules/maintenance'
import attendances from './modules/attendances'
import properties from './modules/properties'
import statements from './modules/statements'
import timesheets from './modules/timesheets'
import noEntries from './modules/no-entries'
import customers from './modules/customers'
import absences from './modules/absences'
import bookings from './modules/bookings'
import invoices from './modules/invoices'
import upload from './modules/upload'
import costs from './modules/costs'
import stock from './modules/stock'
import rotas from './modules/rotas'
import teams from './modules/teams'
import users from './modules/users'
import auth from './modules/auth'
import jobs from './modules/jobs'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    maintenanceContractorTrades,
    maintenanceContractors,
    maintenanceRecurrings,
    checklistTemplates,
    maintenanceVisits,
    bookingPayments,
    propertyKeys,
    bankAccounts,
    maintenance,
    attendances,
    timesheets,
    statements,
    properties,
    noEntries,
    customers,
    absences,
    bookings,
    invoices,
    upload,
    costs,
    rotas,
    stock,
    teams,
    users,
    auth,
    jobs
  }
})
