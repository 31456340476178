import crudModule, {crudActions} from "../crud";

export default {
  ...crudModule('/statements'),
  actions: {
    ...crudActions('/statements'),
    exportAsCSV({commit}, data) {
      const uri = `/statements/export`;
      const reqOpts = {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      };

      commit('exportReq');
      return window.apiFetch(uri, reqOpts).then(res => res.json()).then(json => {
        commit('exportSuc', json);
        return json;
      }).catch(err => {
        commit('exportErr', err);
        throw err;
      });
    },
    toggleApprove({commit}, id) {
      commit('toggleApproveReq');
      return window.apiFetch(`/statements/${id}/toggle-approve`, {
        method: 'POST',
      }).then(res => res.json()).then(json => {
        commit('toggleApproveSuc', json);
        return json;
      }).catch(err => {
        commit('toggleApproveErr');
        throw err;
      });
    },
    sendEmail({commit}, id) {
      const uri = `/statements/${id}/send-email`;
      const reqOpts = {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        }
      };

      commit('sendEmailReq');
      return window.apiFetch(uri, reqOpts).then(res => res.json()).then(json => {
        commit('sendEmailSuc', json);
        return json;
      }).catch(err => {
        commit('sendEmailErr', err);
        throw err;
      });
    }
  }
};
