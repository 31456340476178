import crudModule, { crudActions } from "../crud";

export default {
  ...crudModule('/stock'),
  actions: {
    ...crudActions('/stock'),
    bookInOut(_, data) {
      const reqOpts = {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      };

      // commit('bookInOutReqest');
      return window.apiFetch(`/stock/${data.id}/book-in-out`, reqOpts).then(res => res.json()).then(json => {
        // commit('bookInOutSuccess', json);
        return json;
      }).catch(err => {
        // commit('bookInOut', err);
        throw err;
      });
    },
  },
};
